import { createTheme } from '@mui/material/styles';

// This is a helper so I can tell my styled components if they're in dark theme
// (MUI's theme engine isn't nearly powerful enough sometimes)
export interface PassDarkThemeProps {
    isDarkTheme: boolean;
}

export const getTheme = (isDarkMode: boolean) => {
    return createTheme({
    palette: {
        background: {
            default: isDarkMode ? "#141D2F" : "#FEFEFE"
        },
        primary: {
            main: isDarkMode ? "#1E2A47" : "#FFFFFF",
            light: "#FFFFFF",
            dark: '#141D2F',
            contrastText: '#1E2A47',
        },
    },

    typography: {
        h1: {
            fontFamily: '"Space Mono", monospace',
            fontWeight: "bold",
            fontSize: "26px",
            lineHeight: "38px",
            color: isDarkMode ? "#FFFFFF" : "#141D2F"
        },
        h2: {
            fontFamily: "'Space Mono', monospace",
            fontWeight: "bold",
            fontSize: "22px",
            lineHeight: "33px",
            color: isDarkMode ? "#FFFFFF" : "#141D2F"
        },
        h3: {
            fontFamily: "'Space Mono', monospace",
            fontWeight: "normal",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#0079FF"
        },
        h4: {
            fontFamily: "'Space Mono', monospace",
            fontWeight: "normal",
            fontSize: "13px",
            lineHeight: "20px"
        },
        body1: {
            fontFamily: "'Space Mono', monospace",
            fontWeight: "normal",
            fontSize: "15px",
            lineHeight: "25px"
        },
    }
  })
}

export const darkTheme = () => createTheme({
    palette: {
        background: {
            default: "#141D2F"
        },
        primary: {
            main: '#0079FF',
            light: "#FFFFFF",
            dark: '#141D2F',
            contrastText: '#1E2A47',
        },
        secondary: {
            main: '#edf2ff',
        },
    },
});