import { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";

import { getTheme } from "./style/theme";
import { Container } from "@mui/system";
import { Grid2 } from "@mui/material";
import styled from "@emotion/styled";
import { CssBaseline } from "@mui/material";
import { UserDetails } from "./types/UserDetails";
import HeaderBar from "./components/headerBar";
import SearchBar from "./components/search";
import ProfileCard from "./components/profile";

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(
    undefined
  );

  return (
    <ThemeProvider theme={getTheme(isDarkTheme)}>
      <CssBaseline />
      <Container style={{ maxWidth: "850px" }}>
        <MainGridContainer container columns={16}>
          <Grid2 size={16}>
            <HeaderBar toggleTheme={setIsDarkTheme} isDarkTheme={isDarkTheme} />
          </Grid2>
          <Grid2 size={16}>
            <SearchBar
              setUserDetails={setUserDetails}
              isDarkTheme={isDarkTheme}
            />
          </Grid2>
          <Grid2 size={16}>
            <ProfileCard userDetails={userDetails} isDarkTheme={isDarkTheme} />
          </Grid2>
        </MainGridContainer>
      </Container>
    </ThemeProvider>
  );
}

const MainGridContainer = styled(Grid2)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default App;
