import styled from "@emotion/styled";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { UserDetails } from "../../types/UserDetails";
import { Grid2, Typography } from "@mui/material";
import { color } from "../../style/color";
import { PassDarkThemeProps } from "../../style/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

interface SearchBarProps {
  setUserDetails: React.Dispatch<React.SetStateAction<undefined | UserDetails>>;
  isDarkTheme: boolean;
}

function SearchBar({ setUserDetails, isDarkTheme }: SearchBarProps) {
  const [userName, setUserName] = useState("");

  const [searchSuccess, setSearchSuccess] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchUserDetails("octocat");
  }, []);

  async function fetchUserDetails(userName: string, fallback: boolean = false) {
    console.log(userName);

    const res = await fetch(`https://api.github.com/users/${userName}`);

    if (res.ok) {
      const data = await res.json();

      if (data) {
        const user: UserDetails = {
          email: data.email,
          avatar_url: data.avatar_url,
          login: data.login,
          name: data.name,
          location: data.location,
          bio: data.bio,
          twitter_username: data.twitter_username,
          created_at: data.created_at,
          public_repos: data.public_repos,
          followers: data.followers,
          following: data.following,
          blog: data.blog,
          company: data.company,
        };

        setUserDetails(user);
        setSearchSuccess(!fallback);
      }
    } else {
      setSearchSuccess(false);
      fetchUserDetails("octocat", true);
    }
  }

  const debouncedQuery = useCallback(_debounce(fetchUserDetails, 1000), [
    fetchUserDetails,
  ]);

  function handleInput(event: ChangeEvent<HTMLInputElement>) {
    setUserName(event.target.value);
    debouncedQuery(event.target.value);
  }

  return (
    <InputContainer container isDarkTheme={isDarkTheme} columns={16}>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        style={{
          color: color.lightBlue,
          marginRight: "10px",
        }}
        size="lg"
      />
      <UserInput value={userName} type="text" onInput={handleInput}></UserInput>
      {!searchSuccess && (
        <Typography variant="h3" color="red">
          No Results
        </Typography>
      )}
    </InputContainer>
  );
}

const InputContainer = styled(Grid2)<PassDarkThemeProps>(({ isDarkTheme }) => ({
  backgroundColor: isDarkTheme ? color.darkNavy : color.white1,
  color: isDarkTheme ? color.white1 : color.darkBlue,
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.24)",
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 20px",
  borderRadius: "8px",
  marginRight: "5px",
  outline: "none",
  height: "50px",
  marginBottom: "30px",
  "&:focus": {
    borderColor: "#005ad8",
    transition: "0.5s",
  },
}));

const UserInput = styled.input(() => ({
  display: "flex",
  flexGrow: 1,
  outline: "none",
  background: "transparent",
  boxShadow: "none",
  border: "none",
  color: "inherit",
  fontSize: "18px",
}));

export default SearchBar;
