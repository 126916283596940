// I think MUI's color managment is restrictive and bad - I'm going to make some constants here

export const color = {
    lightBlue: "#0079FF",
    blueGrey: "#697C9A",
    blueGreyAlt: "#4B6A9B",
    darkGrey: "#2B3442",
    white1: "#FFFFFF",
    white2: "#FEFEFE",
    white3: "#F6F8FF",
    darkBlue: "#141D2F",
    darkNavy: "#1E2A47"
}