import styled from "@emotion/styled";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid2, Typography } from "@mui/material";
import { color } from "../../style/color";

interface SocialDataProps {
  icon: IconDefinition;
  value?: string;
  hyperLink?: string;
  isDarkTheme: boolean;
}

function SocialData({ icon, value, hyperLink, isDarkTheme }: SocialDataProps) {
  return (
    <SocialDataContainer
      columns={24}
      container
      isDarkTheme={isDarkTheme}
      isAvailable={value !== "" && value !== undefined}
    >
      <Grid2 size={4}>
        <FontAwesomeIcon
          icon={icon}
          style={{
            color: "inherit",
          }}
          size="lg"
        />
      </Grid2>
      <Grid2>
        <StyledLink href={hyperLink}>
          <Typography variant="body1">
            {value !== "" && value !== undefined ? value : <>Not Available</>}
          </Typography>
        </StyledLink>
      </Grid2>
    </SocialDataContainer>
  );
}

export default SocialData;

const StyledLink = styled.a(({ href }) => ({
  display: "inline-block",
  outline: "none",
  background: "transparent",
  boxShadow: "none",
  border: "none",
  color: "inherit",
  fontSize: "18px",
  textDecoration: "none",
  pointerEvents: href ? "all" : "none",
}));

interface SocialDataContainerLinkProps {
  isAvailable: boolean;
  isDarkTheme: boolean;
}
const SocialDataContainer = styled(Grid2)<SocialDataContainerLinkProps>(
  ({ isDarkTheme, isAvailable }) => ({
    color: isDarkTheme
      ? isAvailable
        ? color.white2
        : color.blueGrey
      : isAvailable
      ? color.darkBlue
      : color.blueGrey,
    display: "flex",
    outline: "none",
    background: "transparent",
    boxShadow: "none",
    border: "none",
    fontSize: "18px",
  })
);
