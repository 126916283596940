import styled from "@emotion/styled";
import { PassDarkThemeProps } from "../../style/theme";
import Grid2 from "@mui/material/Grid2";
import { color } from "../../style/color";
import { Typography } from "@mui/material";

interface MetadataOverviewProps {
  metaData: Map<string, number>;
  isDarkTheme: boolean;
}

function MetadataOverview({ metaData, isDarkTheme }: MetadataOverviewProps) {
  return (
    <ProfileMetadata isDarkTheme={isDarkTheme}>
      {Array.from(metaData).map(([key, value]) => (
        <MetadataEntry isDarkTheme={isDarkTheme}>
          <Typography>{key}</Typography>
          <Typography variant="h2">{value}</Typography>
        </MetadataEntry>
      ))}
    </ProfileMetadata>
  );
}

const ProfileMetadata = styled(Grid2)<PassDarkThemeProps>(
  ({ isDarkTheme }) => ({
    padding: "10px 20px 10px 20px",
    backgroundColor: isDarkTheme ? color.darkBlue : "#E2E2E2", // Our other whites are BARELY different
    color: isDarkTheme ? color.white1 : color.darkBlue,
    borderRadius: "8px",
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
  })
);

const MetadataEntry = styled.div<PassDarkThemeProps>(({ isDarkTheme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export default MetadataOverview;
