import { Grid2, Typography } from "@mui/material";
import { UserDetails } from "../../types/UserDetails";
import styled from "@emotion/styled";
import { color } from "../../style/color";
import { PassDarkThemeProps } from "../../style/theme";
import MetadataOverview from "./MetadataOverview";
import SocialData from "./SocialData";
import {
  faBuilding,
  faLink,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";

interface ProfileCardProps {
  userDetails: UserDetails | undefined;
  isDarkTheme: boolean;
}

function ProfileCard({ userDetails, isDarkTheme }: ProfileCardProps) {
  if (!userDetails) {
    return <></>;
  }

  function getFormattedDate(dateString: string) {
    const date = new Date(dateString);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = String(date.getUTCDate()).padStart(2, "0"); // Get day and ensure 2 digits
    const month = monthNames[date.getUTCMonth()]; // Get month name
    const year = date.getUTCFullYear(); // Get year

    return `${day} ${month} ${year}`;
  }

  const userMetadata = new Map([
    ["Repos", userDetails.public_repos],
    ["Followers", userDetails.followers],
    ["Following", userDetails.following],
  ]);

  console.log(userDetails);

  return (
    <>
      {userDetails ? (
        <StyledProfileContainer
          container
          columns={16}
          isDarkTheme={isDarkTheme}
        >
          <Grid2 size={5} style={{ justifyContent: "right" }}>
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                maxWidth: "200px",
                marginLeft: "20%",
                marginTop: "5%",
              }}
            >
              <StyledImage src={userDetails.avatar_url} />
            </div>
          </Grid2>
          <Grid2 size={11} style={{ padding: "20px 0px 0px 40px" }}>
            <Row>
              <Typography variant="h1">{userDetails?.name}</Typography>
              <Typography variant="body1">
                Joined {getFormattedDate(userDetails.created_at)}
              </Typography>
            </Row>
            <Row>
              <Typography variant="h3">
                <a
                  href={"https://github.com/" + userDetails?.login}
                  style={{ textDecoration: "none", color: color.lightBlue }}
                >
                  @{userDetails?.login}
                </a>
              </Typography>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Typography variant="body1">
                {userDetails.bio ? (
                  <>{userDetails.bio}</>
                ) : (
                  <>This profile has no bio</>
                )}
              </Typography>
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <MetadataOverview
                isDarkTheme={isDarkTheme}
                metaData={userMetadata}
              />
            </Row>

            <Row style={{ marginTop: "20px" }}>
              <Grid2
                container
                rowSpacing={1}
                columns={12}
                style={{ width: "100%" }}
              >
                <Grid2 size={6}>
                  <SocialData
                    icon={faLocationPin}
                    isDarkTheme={isDarkTheme}
                    value={userDetails.location}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <SocialData
                    icon={faXTwitter}
                    isDarkTheme={isDarkTheme}
                    value={
                      userDetails.twitter_username
                        ? "@" + userDetails.twitter_username
                        : undefined
                    }
                    hyperLink={
                      userDetails.twitter_username
                        ? "https://x.com/" + userDetails.twitter_username
                        : undefined
                    }
                  />
                </Grid2>
                <Grid2 size={6}>
                  <SocialData
                    icon={faLink}
                    isDarkTheme={isDarkTheme}
                    value={userDetails.blog}
                    hyperLink={userDetails.blog}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <SocialData
                    icon={faBuilding}
                    isDarkTheme={isDarkTheme}
                    value={userDetails.company}
                  />
                </Grid2>
              </Grid2>
            </Row>
          </Grid2>
        </StyledProfileContainer>
      ) : (
        <></>
      )}
    </>
  );
}

const StyledProfileContainer = styled(Grid2)<PassDarkThemeProps>(
  ({ isDarkTheme }) => ({
    padding: "10px 20px 20px 20px",
    backgroundColor: isDarkTheme ? color.darkNavy : color.white1,
    color: isDarkTheme ? color.white1 : color.darkBlue,
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.24)",
    borderRadius: "8px",
  })
);

const StyledImage = styled.img((size) => ({
  display: "flex",
  borderRadius: "50%", // makes the image round
  objectFit: "contain", // ensures the image covers the container without distortion
  height: "100%",
  width: "100%",
}));

const Row = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "space-between",
  alignContent: "center",
}));

export default ProfileCard;
