import { Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Dispatch, SetStateAction } from "react";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { color } from "../../style/color";
import { PassDarkThemeProps } from "../../style/theme";

interface HeaderBarProps {
  toggleTheme: Dispatch<SetStateAction<boolean>>;
  isDarkTheme: boolean;
}

function HeaderBar({ toggleTheme, isDarkTheme }: HeaderBarProps) {
  return (
    <HeaderContainer>
      <Typography variant="h1" align="center">
        Devfinder Demo
      </Typography>
      <ThemeToggleButton
        onClick={() => {
          toggleTheme(!isDarkTheme);
        }}
        isDarkTheme={isDarkTheme}
      >
        <Typography variant="h2" align="center" color="inherit">
          {isDarkTheme ? "Dark" : "Light"}
        </Typography>
        <FontAwesomeIcon
          icon={isDarkTheme ? faMoon : faSun}
          style={{
            color: "inherit",
          }}
          size="lg"
        />
      </ThemeToggleButton>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div(({ theme }) => ({
  display: "flex",
  margin: "20px 0px 60px 0px",
  justifyContent: "space-between",
}));

const ThemeToggleButton = styled(Button)<PassDarkThemeProps>(
  ({ isDarkTheme }) => ({
    display: "flex",
    flexGrow: 1,
    maxWidth: "100px",
    justifyContent: "space-between",
    transition: ".5s",
    color: isDarkTheme ? color.white2 : color.blueGreyAlt,
    "&:hover": {
      color: isDarkTheme ? color.blueGrey : color.darkBlue,
    },
  })
);

export default HeaderBar;
